import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

import useBreakpoint from 'hooks/useBreakpoint'

import emailIcon from 'assets/icons/email.svg'
import phoneIcon from 'assets/icons/phone.svg'

const Section = styled.section`
  width: 100%;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.lg.min} {
    padding: 0 0 40px 0;
  }
`

const InfoWrapper = styled.div`
  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(5, auto);
    /* height: 70px; */
  }
`

const InfoItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.lg.min} {
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ theme }) => theme.media.xl.min} {
    padding-left: 30px;
    padding-right: 0;
  }
`

const InnerInfoItem = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 0;

    :not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`

const IconWrapper = styled.div`
  margin-right: 10px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

type Props = {
  address: string
  col2Heading: string
  email: string
  phoneNumber: string
  owner: string
  identityNumbers: string
  shareCapital: string
}

const ContactInfo: React.FC<Props> = ({
  address,
  col2Heading,
  email,
  phoneNumber,
  owner,
  identityNumbers,
  shareCapital,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Section title="Kontakt">
      <Container>
        <InfoWrapper>
          <InfoItem>
            <Text
              size={16}
              themecolor="black100"
              margin={lg ? '5px' : '0'}
              align={lg ? 'left' : 'center'}
              dangerouslySetInnerHTML={{ __html: address }}
            />
          </InfoItem>
          <InfoItem>
            <InnerInfoItem>
              <Text
                size={16}
                weight={700}
                themecolor="black100"
                align={lg ? 'left' : 'center'}
                dangerouslySetInnerHTML={{ __html: col2Heading }}
              />
            </InnerInfoItem>
            <InnerInfoItem>
              <IconWrapper>
                <Icon src={emailIcon} size={24} alt="email" />
              </IconWrapper>
              <a href={`mailto:${email}`}>
                <Text
                  size={16}
                  weight={700}
                  themecolor="black100"
                  align={lg ? 'left' : 'center'}
                  dangerouslySetInnerHTML={{ __html: email }}
                />
              </a>
            </InnerInfoItem>
            <InnerInfoItem>
              <IconWrapper>
                <Icon src={phoneIcon} size={24} alt="phone" />
              </IconWrapper>
              <a href={`tel:${phoneNumber}`}>
                <Text
                  size={16}
                  weight={700}
                  themecolor="black100"
                  align={lg ? 'left' : 'center'}
                  dangerouslySetInnerHTML={{ __html: phoneNumber }}
                />
              </a>
            </InnerInfoItem>
          </InfoItem>
          <InfoItem>
            <Text
              size={16}
              themecolor="black100"
              margin="0"
              align={lg ? 'left' : 'center'}
              line={lg ? 1.5 : 1.4}
              dangerouslySetInnerHTML={{
                __html: owner,
              }}
            />
          </InfoItem>
          <InfoItem>
            <Text
              size={16}
              themecolor="black100"
              margin={lg ? '3px' : '0'}
              align={lg ? 'left' : 'center'}
              dangerouslySetInnerHTML={{ __html: identityNumbers }}
            />
          </InfoItem>
          <InfoItem>
            <Text
              size={16}
              themecolor="black100"
              margin="0"
              line={lg ? 1.5 : 1.4}
              align={lg ? 'left' : 'center'}
              dangerouslySetInnerHTML={{
                __html: shareCapital,
              }}
            />
          </InfoItem>
        </InfoWrapper>
      </Container>
    </Section>
  )
}

export default ContactInfo
