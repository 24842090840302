import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import ContactInfo from 'components/layout/Sections/Pages/Kontakt/ContactInfo'
import ContactForm from 'components/layout/Forms/ContactForm/ContactForm'
import FormInfo from 'components/layout/Forms/ContactForm/FormInfo'
import Map from 'components/layout/GoogleMap/Map'

import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Kontakt',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Kontakt',
    },
  ],
}

const KontaktPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.kontakt
  const PAGE_SEO = data?.wpPage?.seo

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.companyDataImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.companyDataImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.companyDataTitle}
        breadcrumbs={breadcrumbs}
      />
      <ContactInfo
        address={PAGE?.companyDataAddress}
        col2Heading={PAGE?.companyDataTitle2}
        email={PAGE?.companyDataEMail}
        phoneNumber={PAGE?.companyDataPhoneNumber}
        owner={PAGE?.companyDataOwner}
        identityNumbers={PAGE?.companyDataKrsRegonNip}
        shareCapital={PAGE?.companyDataShareCapital}
      />
      <Map
        pins={[
          {
            lat: PAGE.companyDataLat,
            lng: PAGE.companyDataLng,
            pinSize: 45,
          },
        ]}
        defaultZoom={1.7}
        defaultZoomLG={12}
        defaultCenter={{
          lat: Number(PAGE.companyDataLat),
          lng: Number(PAGE.companyDataLng),
        }}
      />
      <ContactForm />
      <FormInfo infoText={PAGE?.companyDataAdminData} />
    </Layout>
  )
}

export default KontaktPage

export const query = graphql`
  query KontaktPage {
    wpPage(slug: { regex: "/kontakt/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      kontakt {
        companyDataAddress
        companyDataAdminData
        companyDataEMail
        companyDataKrsRegonNip
        companyDataLat
        companyDataLng
        companyDataOwner
        companyDataPhoneNumber
        companyDataShareCapital
        fieldGroupName
        companyDataTitle
        companyDataTitle2
        companyDataImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
