import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  infoText: string
}

const StyledContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;

  ${({ theme }) => theme.media.xxl.min} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`

const FormInfo: React.FC<Props> = ({ infoText }) => {
  const { lg } = useBreakpoint()

  return (
    <StyledContainer>
      <Text
        size={lg ? 15 : 14}
        themecolor="black100"
        weight={400}
        dangerouslySetInnerHTML={{ __html: infoText }}
      />
    </StyledContainer>
  )
}

export default FormInfo
